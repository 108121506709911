import React, { useState, Suspense, lazy } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../LoadingIndicator";
import EHO from "./images/eho_grey.png";
import TOP_IMG from "./images/Top-graphic.jpg";
import TOP_IMG_MOBILE from "./images/Top-graphic-mobile.jpg";
import BOTTOM_IMG from "./images/Bottom-graphic.png";
// import styles from "./index.module.css";

const LeadGenerator = lazy(() => import("../GenerateLead"));
const MainPage = (props) => {
  const [applyModalShow, setApplyModalShow] = useState(false);
  const handleApplyModal = () => setApplyModalShow(false);
  return (
    <>
      <div className={`hero-image container-fluid`}>
        <div className="row">
          <div className="col-12 d-none d-sm-none d-md-block">
            <img src={TOP_IMG} className="img-fluid" alt="Big white house" />
          </div>
          <div className="col-12 d-block d-sm-block d-md-none">
            <img
              src={TOP_IMG_MOBILE}
              className="img-fluid"
              alt="Big white house mobile version"
            />
          </div>
        </div>
      </div>
      <main role="main" className="container mt-5">
        <div className="row pt-4">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h6 className="customBlue">
              <strong>Portfolio Full Doc:</strong>
            </h6>
            <p className="babyBlue">
              Primary Residence, Second home, and Investment Property
            </p>
            <ul className="productsList">
              <li className="sunWest">Loan Amount up to $5,000,000</li>
              <li className="sunWest">Credit Score (Fico) as low as 600</li>
              <li>Max Loan-to-Value (LTV) of 90%</li>
              <li>80% LTV to $2,000,000 w/680 Fico</li>
              <li>Debt to income (DTI) up to 50%</li>
              <li>Interest Only (I/O) payment options available</li>
              <li>40 year terms w/10 yrs of I/O available</li>
              <li>12 &amp; 24 mos of income documentation options</li>
              <li>Gift funds allowed</li>
              <li>Impounds/Escrows for property tax and insurance required</li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h6 className="customBlue">
              <strong>Portfolio Reduced Docs:</strong>
            </h6>
            <p className="babyBlue">
              Primary Residence, Second home, and Investment Property
            </p>
            <ul className="productsList">
              <li className="sunWest">Loan Amount up to $5,000,000</li>
              <li className="sunWest">Credit Score (Fico) as low as 600</li>
              <li>Qualify with 12 or 24 mos of Bank Statements</li>
              <li>Self Employed borrowers only</li>
              <li>Business or Personal bank statements used for qualifying</li>
              <li>Loan-to-Value (LTV) up to 90%</li>
              <li>One appraisal required for loan amount up to $1M</li>
              <li>Debt-to-Income (DTI) ratio up to 50%</li>
              <li>Interest Only options available</li>
              <li>Impounds/Escrows for property tax and insurance required</li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4">
            <h6 className="customBlue">
              <strong>Portfolio Debt Service Coverage Ratio (DSCR):</strong>
            </h6>
            <p className="babyBlue">Investment Properties only</p>
            <ul className="productsList">
              <li className="sunWest">Loan Amount up to $2,000,000</li>
              <li className="sunWest">Credit Score (Fico) as low as 620</li>
              <li>No Income Documentation Required</li>
              <li>80% Loan-to-Value (LTV) to $2,000,000 w/680 Fico</li>
              <li>DSCR &ge; 1.0</li>
              <li>Impounds/Escrows for property tax and insurance required</li>
            </ul>
          </div>
        </div>
        <div className="container text-center">
          <div className="row justify-content-center mt-5">
            <div className="col-md-4 text-center">
              <a
                // href="https://www.swmc.com/ApplyNow/"
                // target="_blank"
                // rel="noreferrer"
                title="Apply for loan Now!"
                className="btn btn-primary w-50"
                onClick={() => {
                  setApplyModalShow(true);
                }}
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
        <div className="globalApplyModalWrapper">
          <Suspense fallback={<div />}>
            {applyModalShow && (
              <Modal
                size="lg"
                show={applyModalShow}
                onHide={handleApplyModal}
                className="globalApplyModal"
              >
                <Modal.Header closeButton></Modal.Header>
                <Suspense fallback={<Loader />}>
                  {applyModalShow && (
                    <LeadGenerator
                      leadSource="SWMC.COM"
                      branch="058"
                      message="This is from https://electra.sunwestmortgage.com/."
                      closeModal={handleApplyModal}
                    />
                  )}
                </Suspense>
              </Modal>
            )}
          </Suspense>
        </div>
      </main>

      <footer>
        <div className="container-fluid pl-0">
          <div className="container" id="footer">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 text-center">
                  &copy;
                  {new Date().getFullYear()} Sun West Mortgage Company, Inc. All
                  Rights Reserved
                  <p>
                    <strong>
                      Please{" "}
                      <a
                        href="https://www.swmc.com/disclaimer.php"
                        target="_blank"
                        rel="noreferrer"
                        title="Click Here for full list of license information of Sun West Mortgage Company, Inc."
                      >
                        Click Here
                      </a>{" "}
                      or refer to{" "}
                      <a
                        href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277"
                        target="_blank"
                        rel="noreferrer"
                        title="NML Consumer Access"
                      >
                        www.nmlsconsumeraccess.org
                      </a>{" "}
                      to see where Sun West Mortgage Company, Inc. (NMLS ID
                      3277) is a licensed lender and servicer.
                    </strong>{" "}
                    <a
                      href="https://www.swmc.com/TXdis.php"
                      target="_blank"
                      rel="noreferrer"
                      title="View Texas Complaint Notice and Servicing Disclosure"
                    >
                      Click Here
                    </a>{" "}
                    to view Texas Complaint Notice and Servicing Disclosure.
                  </p>
                  <p>
                    <strong>
                      Although Sun West Mortgage is approved to conduct business
                      in the state of New York, this website has not yet been
                      approved by the State of New York Department of Financial
                      Services. For properties located in the State of New York,
                      this website can not be used to upload an application,
                      please visit{" "}
                      <a
                        href="https://www.swmc.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        SWMC.com
                      </a>{" "}
                      to upload an application.
                    </strong>
                  </p>
                </div>
                <div className="col-md-12 text-center">
                  <img
                    src={EHO}
                    style={{ height: "75px" }}
                    title="Equal Housing Opportunity"
                    alt="Equal Housing Logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0 d-none d-sm-none d-md-none d-lg-block">
            <img src={BOTTOM_IMG} className="w-100" alt="" />
          </div>
        </div>
      </footer>
    </>
  );
};

export default MainPage;
